import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/part_1',
    name: 'part_1',
    component: () =>
      import(/* webpackChunkName: "part_1" */ '../views/part_1/index.vue')
  },
  {
    path: '/part_2',
    name: 'part_2',
    component: () =>
      import(/* webpackChunkName: "part_2" */ '../views/part_2/index.vue')
  },
  {
    path: '/guide',
    name: 'guide',
    component: () =>
      import(/* webpackChunkName: "guide" */ '../views/guide/index.vue')
  },
  {
    path: '/review',
    name: 'review',
    component: () =>
      import(/* webpackChunkName: "review" */ '../views/review/index.vue')
  },
  {
    path: '/',
    redirect: '/guide'
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
